.component12 {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 235px;
  object-fit: cover;
  opacity: 0.34;
}
.thePsxClub {
  position: absolute;
  bottom: 16px;
  left: calc(50% - 146px);
  font-weight: 300;
}
.logoVector07Icon {
  position: absolute;
  top: 19px;
  left: 18px;
  width: 157px;
  height: 111px;
  object-fit: cover;
  cursor: pointer;
}
.menu {
  width: 61px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 49px;
  right: 19px;
}
.fantasyGenerator {
  margin: 0;
  position: absolute;
  top: calc(50% - 291px);
  left: calc(50% - 119px);
  font-size: var(--font-size-3xl);
  font-weight: 800;
  font-family: inherit;
}
.fantasyGenerator11Child {
  position: absolute;
  width: calc(100% - 37px);
  top: calc(50% - 216px);
  right: 18px;
  left: 19px;
  border-radius: 39px;
  background-color: var(--color-plum);
  filter: blur(16px);
  height: 231px;
  opacity: 0.4;
}
.completedButton,
.giveMoreButton,
.socialLinksIcon {
  width: 199px;
  position: absolute;
  top: calc(50% + 83px);
  left: calc(50% - 99px);
}
.giveMoreButton,
.socialLinksIcon {
  top: calc(50% + 142px);
}
.socialLinksIcon {
  top: calc(50% + 402.07px);
  left: calc(50% - 32.05px);
  width: 64.11px;
  height: 13.43px;
  object-fit: cover;
}
.fantasyGenerator11 {
  position: relative;
  background-color: var(--color-darkslateblue);
  width: 100%;
  height: 932px;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-montserrat);
}
.fantasyBackground {
  position: absolute;
  width: calc(100% - 37px);
  top: calc(50% - 216px);
  right: 18px;
  left: 19px;
  border-radius: 39px;
  background-color: var(--color-plum);
  filter: blur(16px);
  height: 231px;
  opacity: 0.4;
  z-index: 0;
}

.fantasyContent {
  position: absolute;
  width: calc(100% - 37px);
  top: calc(50% - 216px);
  right: 18px;
  left: 19px;
  height: 231px;
  z-index: 1;

  color: var(--color-white);
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
}

.completedButton, .giveMoreButton {
  background-color: white;
  border: 1px solid #707070;
  color: black;
  width: 238px; /* Adjust this if the buttons are of different sizes */
}

.completedButton:hover, .giveMoreButton:hover {
  background-color: #B796E3;
  color: white;
}

@media (max-width: 768px) {
  .component12 {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    height: 235px;
    object-fit: cover;
    opacity: 0.34;
  }
  .thePsxClub {
    position: absolute;
    bottom: 16px;
    left: calc(50% - 146px);
    font-weight: 300;
  }
  .logoVector07Icon {
    position: absolute;
    top: 19px;
    left: 18px;
    width: 157px;
    height: 111px;
    object-fit: cover;
    cursor: pointer;
  }
  .menu {
    width: 61px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 49px;
    right: 19px;
  }
  .fantasyGenerator {
    margin: 0;
    position: absolute;
    top: calc(50% - 291px);
    left: calc(50% - 119px);
    font-size: var(--font-size-3xl);
    font-weight: 800;
    font-family: inherit;
  }
  .fantasyGenerator11Child {
    position: absolute;
    width: calc(100% - 37px);
    top: calc(50% - 216px);
    right: 18px;
    left: 19px;
    border-radius: 39px;
    background-color: var(--color-plum);
    filter: blur(16px);
    height: 231px;
    opacity: 0.4;
  }
  .completedButton,
  .giveMoreButton,
  .socialLinksIcon {
    width: 199px;
    position: absolute;
    top: calc(50% + 83px);
    left: calc(50% - 99px);
  }
  .giveMoreButton,
  .socialLinksIcon {
    top: calc(50% + 142px);
  }
  .socialLinksIcon {
    top: calc(50% + 402.07px);
    left: calc(50% - 32.05px);
    width: 64.11px;
    height: 13.43px;
    object-fit: cover;
  }
  .fantasyGenerator11 {
    position: relative;
    background-color: var(--color-darkslateblue);
    width: 100%;
    height: 932px;
    text-align: center;
    font-size: var(--font-size-base);
    color: var(--color-white);
    font-family: var(--font-montserrat);
  }
  .fantasyBackground {
    position: absolute;
    width: calc(100% - 37px);
    top: calc(50% - 216px);
    right: 18px;
    left: 19px;
    border-radius: 39px;
    background-color: var(--color-plum);
    filter: blur(16px);
    height: 231px;
    opacity: 0.4;
    z-index: 0;
  }
  
  .fantasyContent {
    position: absolute;
    width: calc(100% - 37px);
    top: calc(50% - 216px);
    right: 18px;
    left: 19px;
    height: 231px;
    z-index: 1;
  
    color: var(--color-white);
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
  }
  
  .completedButton, .giveMoreButton {
    background-color: white;
    border: 1px solid #707070;
    color: black;
    width: 238px;
    left: 20%
  }
  
  .completedButton:hover, .giveMoreButton:hover {
    background-color: #B796E3;
    color: white;
  }
}